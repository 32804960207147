import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { ReactElement } from "react";

interface SwitchProps {
  onChange: (e: SwitchChangeEvent) => void;
  defaultChecked?: boolean;
}
function KendoSwitch({ onChange, defaultChecked }: SwitchProps): ReactElement {
  return <Switch onChange={onChange} defaultChecked={defaultChecked} />;
}

export default KendoSwitch;
