import "@progress/kendo-theme-material/dist/all.css";
import "./App.css";
import { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./auth/AuthProvider";

export default function App(): ReactElement {
  return (
    <BrowserRouter>
      <div className="topHeader">
        <div className="container">
          <div className="topMessage">
            If you or someone you know is experiencing an emergency, please dial{" "}
            <a href="tel:988">988</a>. You can also text LIFE to{" "}
            <a href="sms:741741?&body=LIFE">741741</a>, help is standing by.
          </div>
        </div>
      </div>
      <header>
        <div>
          <a href="/">
            <img
              src="/imgs/h4m_logo.png"
              alt="Hearts4Minds Logo"
              className="logo"
            />
          </a>
        </div>
        <div className="go-back-notice">
          <a href="https://www.hearts4minds.org/" style={{ color: "black" }}>
            <i className="fa-solid fa-arrow-left go-back-icon" />
            Return to hearts4minds.org
          </a>
        </div>
      </header>
      <div className="main-wrapper">
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </div>
      <footer className="pb-2">
        <div className="container-full">
          <div className="topSection">
            <div className="leftCol">
              <img
                src="/imgs/H4M_logo-white.png"
                alt="Hearts4Minds Logo"
                className="logo-white"
              />
            </div>
            <div className="middleCol">
              <p className="footer-about">
                <span className="primary-text">Hearts 4 Minds</span> drives
                innovative solutions to break down the stigma of mental illness,
                raise awareness about the importance of mental health, improve
                the mental health journey and connect people to resources. Join
                us in our mission to improve the long-term treatment outcomes of
                those struggling with mental illness.{" "}
              </p>
              <div className="mt-2 center">
                <h5>Together, we can save lives.</h5>
                <div className="partner-logos">
                  <div className="p-logo">
                    <img
                      src="/imgs/OD2A_logo.png"
                      alt="Overdose Data to Action"
                    />
                  </div>
                  <div className="p-logo">
                    <img
                      src="/imgs/florida_health_logo.jpeg"
                      alt="Florida Health"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rightCol">
              <h5>Hearts 4 Minds</h5>
              <ul className="footer-links">
                <li>
                  <a href="https://www.hearts4minds.org/donate">Donate</a>
                </li>
                <li>
                  <a href="https://www.hearts4minds.org/shop">Shop</a>
                </li>
                <li>
                  <a href="https://www.hearts4minds.org/resources">Resources</a>
                </li>
                <li>
                  <a href="https://www.hearts4minds.org/our-story">Our Story</a>
                </li>
                <li>
                  <a href="https://www.hearts4minds.org/news">News</a>
                </li>
                <li>
                  <a href="https://www.hearts4minds.org/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright mt-5 mb-2">
            © 2024 Hearts 4 Minds. All Rights Reserved. Hearts 4 Minds is a 501
            (c)(3) non-profit recognized by the IRS. Tax ID Number: 83-1575838
          </div>
        </div>
      </footer>
    </BrowserRouter>
  );
}
