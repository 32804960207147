import { Chip, ChipProps } from "@progress/kendo-react-buttons";
import { ReactElement } from "react";

function AnswerChip({ dataItem }: ChipProps): ReactElement {
  return (
    <Chip
      className="answerChip"
      key={`ans-${dataItem.id}`}
      id={`ans-${dataItem.id}`}
      value={dataItem.id}
      text={dataItem.answerText}
    />
  );
}

export default AnswerChip;
