import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Answer, ProviderViewModel, Question } from "../../services/index.defs";
import { ProviderService } from "../../services/ProviderService";
import { QuestionService } from "../../services/QuestionService";
import BrandedPrimaryButton from "../branded/BrandedPrimaryButton";
import AddProviderForm from "./AddProviderForm";

function AddNewProvider(): ReactElement {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);
    QuestionService.getAllAdminQuestions()
      .then((response) => {
        setQuestions(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching questions:", e);
        setIsLoading(false);
        return e;
      });
    QuestionService.getAllAnswers()
      .then((response) => {
        setAnswers(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching answers:", e);
        setIsLoading(false);
        return e;
      });
  }, []);
  const onSave = (pro: ProviderViewModel) => {
    if (pro) {
      console.log(pro);
      ProviderService.addNewProvider({
        body: pro,
      })
        .then((response) => {
          console.log("Provider added", response);
          navigate(`/EditProvider/${response.id}`);
          return response;
        })
        .catch((error) => {
          setMessage("There was an issue adding this provider.");
          setMessageType("error");
          console.error("Error adding provider:", error);
        });
    }
  };
  if (isLoading)
    return (
      <div className="providerWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Loading...</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  return (
    <div className="providerWrapper">
      <div className="container-full centerflexbox flexdircol">
        <Card style={{ width: "100%", maxWidth: "1280px" }}>
          <CardHeader>
            <div>
              <h4>Add a New Provider</h4>
            </div>
            <div>
              <BrandedPrimaryButton
                text="Go Back to Providers"
                onClick={() => navigate("/providers")}
                width="100px"
                className="secondary"
              />
            </div>
          </CardHeader>
          <CardBody>
            {message !== "" && <div className={messageType}>{message}</div>}
            <AddProviderForm
              questions={questions}
              answers={answers}
              onSave={onSave}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddNewProvider;
