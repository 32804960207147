import { jwtDecode } from "jwt-decode";
import { PropsWithChildren, ReactElement, useCallback, useState } from "react";
import { LoginResult } from "../services/index.defs";
import ValueOf from "../utils/ValueOf";
import {
  getStorageRefreshToken,
  getStorageRememberMeToken,
  getStorageUsername,
  removeStorageRememberMeToken,
  setStorageRefreshToken,
  setStorageRememberMeToken,
  setStorageUsername,
  storageLogout,
} from "./auth";
import authContext from "./authContext";
import claimTypes from "./claimTypes";
import { User } from "./User";

function AuthProvider({ children }: PropsWithChildren): ReactElement {
  const initialRefreshToken = getStorageRefreshToken();
  const initialRememberMeToken = getStorageRememberMeToken();
  const initialUsername = getStorageUsername();

  const [accessToken, setAccessToken] = useState<string | null>();
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const [rememberMeToken, setRememberMeToken] = useState(
    initialRememberMeToken,
  );
  const [user, setUser] = useState<User>();
  const [username, setUsername] = useState(initialUsername);

  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const getUserFromToken = (token: string | undefined): User => {
    if (!token) {
      return undefined;
    }

    const tokenObject =
      jwtDecode<Record<ValueOf<typeof claimTypes>, string>>(token);
    const { exp } = tokenObject;
    const name = tokenObject[claimTypes.name];
    const userId = Number(tokenObject[claimTypes.userId]);

    return {
      name,
      userId,
      expires: Number(exp),
    };
  };

  const setUserFromToken = useCallback((newToken: string | undefined): void => {
    const newUser = getUserFromToken(newToken);
    const newUsername = newUser?.name ?? null;
    setUsername(newUsername);
    setStorageUsername(newUsername);
    setAccessToken(newToken);
    setUser(newUser);
  }, []);

  const setAllRefreshToken = useCallback((newRefreshToken: string) => {
    setStorageRefreshToken(newRefreshToken);
    setRefreshToken(newRefreshToken);
  }, []);

  const setAllRememberMeToken = useCallback(
    (newRememberMeToken: string | null) => {
      setStorageRememberMeToken(newRememberMeToken);
      setRememberMeToken(newRememberMeToken);
    },
    [],
  );

  const logout = useCallback(() => {
    console.log("logout started");
    setUser(undefined);
    setAccessToken(null);
    setRefreshToken(null);
    setRememberMeToken(null);
    storageLogout();
    if (isPromptOpen) {
      setIsPromptOpen(false);
    }
  }, [isPromptOpen]);

  const login = useCallback(
    ({
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
      rememberMeToken: newRememberMeToken,
    }: LoginResult) => {
      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      setUserFromToken(newAccessToken);
      setAllRefreshToken(newRefreshToken);
      setAllRememberMeToken(newRememberMeToken ?? null);
    },
    [setAllRefreshToken, setAllRememberMeToken, setUserFromToken],
  );

  const removeRememberMeToken = useCallback(() => {
    removeStorageRememberMeToken();
    setRememberMeToken(null);
  }, []);

  const { Provider } = authContext;

  return (
    <Provider
      value={{
        accessToken,
        login,
        logout,
        refreshToken,
        rememberMeToken,
        removeRememberMeToken,
        user,
        username,
      }}
    >
      {children}
    </Provider>
  );
}

export default AuthProvider;
