import { Button } from "@progress/kendo-react-buttons";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import KendoTextInput from "../shared/KendoTextInput";

function Home(): ReactElement {
  const [zip, setZip] = useState("");
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const handleBeginSearchClick = () => {
    if (zip === "") {
      setShowError(true);
    } else {
      setShowError(false);
      navigate(`/Survey/${zip}`);
    }
  };
  return (
    <div>
      <div
        className="surveyStartWrapper"
        style={{ backgroundImage: "url(/imgs/h4m_light_bg.png)" }}
      >
        <div
          style={{ backgroundImage: "url(/imgs/h4m_top_dragon_path.svg)" }}
          className="heroDragonPath"
        />
        <div className="container centerflexbox">
          <div className="home-positive-messaging">
            <p>
              <span>It takes a lot of courage to ask for help!</span> We&apos;re
              glad you are here. If this is an emergency, please call 988
            </p>
          </div>
          <Card>
            <CardBody>
              <div className="surveyIntroText">
                <p className="bold">
                  Feeling lost in the maze of mental health resources?
                </p>
                Whether you&apos;re seeking support for yourself or a loved one,
                navigating unfamiliar territory can be stressful. Here at Hearts
                4 Minds, we understand that challenge. That&apos;s why
                we&apos;ve created this interactive resource to help you find
                mental health resources with ease. With a few clicks, you can
                find therapists, support groups, hotlines, and more, all
                tailored to your specific needs. <br />
                <br /> We believe everyone deserves access to quality mental
                healthcare, and this resource is here to empower you on your
                journey to well-being.
                <br />
                <br />
                <p className="bold">
                  Take the first step towards well-being. Explore our
                  interactive resource today!
                </p>
              </div>
              <div className="mt-2 borderTopGreen">
                <div className="zipCTA">Please enter your zip</div>
                {showError && (
                  <div className="errorHint">
                    <i className="fa-solid fa-x" /> Zip is required to begin
                    search
                  </div>
                )}
                <div className="mt-2">
                  <KendoTextInput onChange={setZip} required />
                </div>
                <div className="center mt-2">
                  <Button
                    title="Begin Your Search"
                    onClick={handleBeginSearchClick}
                    className="mt-2 secondary"
                  >
                    Begin Your Search
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="providerCTA">
            <span className="text">
              If you are a provider and wish to be listed in our directory,
              please click here.
            </span>
            <span>
              <Button
                title="Are you a provider?"
                onClick={() => navigate("/ProviderRequest")}
                className="mt-2 primary"
              >
                Are you a provider?
              </Button>
            </span>
          </div>
        </div>
      </div>
      <div className="surveyBottom">
        <div className="small-disclaimer">
          Hearts 4 Minds (H4M) provides this list as a compilation of resources
          available in the geographic area you selected. Please note that H4M
          does not guarantee the availability or quality of these services.
        </div>
        <div
          className="leftSide"
          style={{
            backgroundImage: "url(/imgs/h4m_bottom_left_dragonfly.svg)",
          }}
        />

        <div
          className="rightSide"
          style={{
            backgroundImage: "url(/imgs/h4m_bottom_right_path-1.2.svg)",
          }}
        />
      </div>
    </div>
  );
}
export default Home;
