import { useContext } from "react";
import authContext from "./authContext";

const useSetTokens = (): {
  accessToken: string | undefined | null;
  refreshToken: string | undefined | null;
  rememberMeToken: string | undefined | null;
  username: string | undefined | null;
} => {
  const { accessToken, refreshToken, rememberMeToken, username } =
    useContext(authContext);

  return { accessToken, refreshToken, rememberMeToken, username };
};

export default useSetTokens;
