import "@progress/kendo-theme-material/dist/all.css";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { MouseEvent, useEffect, useState } from "react";
import { ResourceViewModel } from "../../services/index.defs";
import { ResourceService } from "../../services/ResourceService";
import KendoDropdownList from "../KendoDropdownList";
import KendoPrimaryButton from "../KendoPrimaryButton";
import KendoSwitch from "../KendoSwitch";
import KendoTextInput from "../KendoTextInput";
import ResourceUploadWithPreview from "./ResourceUploadWithPreview";

function ResourceForm() {
  // Resource Form
  const [resource, setResource] = useState<ResourceViewModel | null>(null);
  const [resourceType, setResourceType] = useState({ text: "", id: "" });
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [dropDownData, setDropDownData] = useState<
    Array<{ text: string; id: string }>
  >([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFileInfo[]>([]);

  // Submit
  const handleSubmitEvent = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (resource) {
      const resourceViewModel: ResourceViewModel = {
        resourceType: resource.resourceType || 0,
        resourceName: resource.resourceName || "",
        description: resource.description || "",
        url: resource.url || "",
        approvalStatus: resource.approvalStatus || 0,
        blobPath: "",
      };
      // const image = uploadedFiles[0].getRawFile();
      console.log(JSON.stringify(resourceViewModel));
      ResourceService.addNewResource({
        body: resourceViewModel,
      })
        .then((response) => {
          if (uploadedFiles && uploadedFiles.length > 0 && uploadedFiles[0]) {
            const image = uploadedFiles[0].getRawFile
              ? uploadedFiles[0].getRawFile()
              : null;
            if (image) {
              const resourceId = response.id;
              ResourceService.uploadResourceImage({
                resourceId,
                image,
              })
                .then((r) => {
                  console.log("Image Added", r);
                  return response;
                })
                .catch((error) => {
                  console.log("Error adding Image", error);
                  return error;
                });
            }
          }

          console.log("Resource Added", response);
          return response;
        })
        .catch((error) => {
          console.error("Error adding resource:", error);
        });
    }
  };

  // ResourceType Dropdown
  const resourceTypeData = async (): Promise<
    Array<{ text: string; id: string }>
  > => {
    try {
      const response = await ResourceService.getAllResources();

      const transformedData = response.map((resourceData) => ({
        text: resourceData.resourceName || "Unnamed Resource",
        id: resourceData.id.toString(),
      }));
      return transformedData;
    } catch (error) {
      console.error("Error Getting Resources", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await resourceTypeData();
        setDropDownData(result);
      } catch (error) {
        console.error("Error getting dropdown data:", error);
      }
    };
    fetchData();
  }, []);

  const dropdownDefaultItem = { text: "Select a Resource Type...", id: "0" };

  return (
    <div className="card">
      <div>Resource Form</div>
      <div>
        <KendoDropdownList
          onChange={setResourceType}
          data={dropDownData}
          textField="text"
          defaultItem={dropdownDefaultItem}
          dataItemKey="id"
          value={resourceType}
        />
      </div>
      <div>
        <div>
          <KendoTextInput
            onChange={setName}
            placeholder="Resource Name"
            value={name}
          />
        </div>
        <div>
          <KendoTextInput
            onChange={setDescription}
            placeholder="Description"
            value={description}
          />
        </div>
        <div>
          <KendoTextInput
            onChange={setWebsite}
            placeholder="URL"
            value={website}
          />
        </div>
        <div>
          <ResourceUploadWithPreview
            onFilesChange={(files) => setUploadedFiles(files)}
          />
        </div>
        <div>
          <KendoSwitch
            onChange={(event: SwitchChangeEvent): void => {
              setResource({
                ...resource,
                approvalStatus: +event.target.value.toString(),
              });
            }}
            defaultChecked={false}
          />
        </div>
      </div>
      <div>
        <KendoPrimaryButton onClick={handleSubmitEvent} text="Save" />
      </div>
    </div>
  );
}

export default ResourceForm;
