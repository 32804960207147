import { Card, CardBody } from "@progress/kendo-react-layout";
import { ReactElement, useEffect, useState } from "react";
import { Answer, ProviderViewModel, Question } from "../../services/index.defs";
import { ProviderService } from "../../services/ProviderService";
import { QuestionService } from "../../services/QuestionService";
import ProviderRequestForm from "./ProviderRequestForm";

function ProviderRequest(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);
    QuestionService.getAllProviderQuestions()
      .then((response) => {
        setQuestions(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching questions:", e);
        setIsLoading(false);
        return e;
      });
    QuestionService.getAllAnswers()
      .then((response) => {
        setAnswers(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching answers:", e);
        setIsLoading(false);
        return e;
      });
  }, []);
  const onSave = (pro: ProviderViewModel) => {
    if (pro) {
      console.log(pro);
      ProviderService.addNewProvider({
        body: pro,
      })
        .then((response) => {
          console.log("Provider added", response);
          window.scrollTo(0, 0);
          setMessage(
            "Thank you for your interest, we will review your information shortly.",
          );
          setMessageType("success");
          return response;
        })
        .catch((error) => {
          console.error("Error adding provider:", error);
          window.scrollTo(0, 0);
          setMessage(
            "There was an issue submitting your form, please try again or email us directly at info@hearts4minds.org.",
          );
          setMessageType("error");
        });
    }
  };
  return (
    <>
      {isLoading && (
        <div className="providerWrapper">
          <div className="container centerflexbox flexdircol">
            <Card className="mt-2">
              <CardBody>
                <div>Loading...</div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="providerWrapper">
          <div className="container centerflexbox flexdircol">
            <Card className="mt-2">
              <CardBody>
                <div>
                  <h4>Provider Interest Form</h4>
                  {message === "" && (
                    <span>
                      Welcome to the Provider page and your interest in Hearts 4
                      Minds
                    </span>
                  )}

                  {message !== "" && (
                    <>
                      <div className={messageType}>{message}</div>
                      <a href="/">Go Back to Home Page</a>
                    </>
                  )}
                </div>
                <div className="mt-2">
                  <ProviderRequestForm
                    questions={questions}
                    answers={answers}
                    onSave={onSave}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="providerBottom">
            <div
              className="leftSide"
              style={{
                backgroundImage: "url(/imgs/h4m_bottom_left_dragonfly.svg)",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ProviderRequest;
