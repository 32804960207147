import { ChipList, ChipListChangeEvent } from "@progress/kendo-react-buttons";
import { ReactElement, useEffect, useState } from "react";
import { Answer, Question } from "../services/index.defs";
import AnswerChip from "./KendoAnswerChip";

interface KendoChipListProps {
  question: Question;
  answers: Answer[];
  chipListValue: any;
  setChipListValue: (s: any) => void;
  selType: string;
  selection: string;
  onAnswerSelect?: (questionId: number, answerId: number) => void;
}

function KendoChipList({
  question,
  answers,
  chipListValue,
  setChipListValue,
  selType,
  selection,
  onAnswerSelect,
}: KendoChipListProps): ReactElement {
  const [answerId, setAnswerId] = useState<number | null>(null);

  useEffect(() => {
    if (selection === "single" && typeof chipListValue === "number") {
      setAnswerId(chipListValue);
    } else if (
      selection === "multiple" &&
      Array.isArray(chipListValue) &&
      chipListValue.length > 0
    ) {
      setAnswerId(chipListValue[0]);
    } else {
      setAnswerId(null);
    }
  }, [chipListValue, selection]);

  const handleChipListChange = (e: ChipListChangeEvent) => {
    if (selection === "single") {
      setChipListValue(e.value as number);
      setAnswerId(e.value as number);
      if (onAnswerSelect) {
        onAnswerSelect(question.id, e.value as number);
      }
    } else {
      setChipListValue(e.value as number[]);
      if (onAnswerSelect) {
        onAnswerSelect(question.id, e.value as number);
      }
    }
  };

  return (
    <ChipList
      className="answerChipList"
      key={`q-${selType}+${question.id}`}
      id={`q-${selType}+${question.id}`}
      data={answers}
      selection={selection}
      value={selection === "single" ? answerId : chipListValue}
      chip={AnswerChip}
      onChange={handleChipListChange}
    />
  );
}

export default KendoChipList;
