import {
  Upload,
  UploadFileInfo,
  UploadListItemProps,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { ComponentType, ReactElement } from "react";
import useAuthHeader from "../auth/useAuthHeader";

interface KendoUploadProps {
  batch?: boolean;
  multiple?: boolean;
  defaultFiles?: UploadFileInfo[];
  withCredentials?: boolean;
  listItemUI?: ComponentType<UploadListItemProps>;
  restrictions?: { allowedExtensions: [] };
  onAdd?: (event: UploadOnAddEvent) => void;
  onRemove?: (event: UploadOnRemoveEvent) => void;
  files?: UploadFileInfo[];
}
function KendoUpload({
  batch,
  multiple,
  defaultFiles,
  withCredentials,
  listItemUI,
  restrictions,
  onAdd,
  onRemove,
  files,
}: KendoUploadProps): ReactElement {
  const authHeader = useAuthHeader();
  return (
    <Upload
      batch={batch}
      multiple={multiple}
      defaultFiles={defaultFiles}
      withCredentials={withCredentials}
      listItemUI={listItemUI}
      restrictions={restrictions}
      onAdd={onAdd}
      onRemove={onRemove}
      files={files}
      saveHeaders={authHeader}
    />
  );
}

export default KendoUpload;
