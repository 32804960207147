import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { MouseEvent, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosInstance from "../../auth/useAxiosInstance";
import useSetTokens from "../../auth/useSetTokens";
import { AuthenticateService } from "../../services/AuthenticateService";
import { LoginModel } from "../../services/index.defs";
import KendoPrimaryButton from "../../shared/KendoPrimaryButton";
import KendoTextInput from "../../shared/KendoTextInput";

function Login(): ReactElement {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const instanceReady = useAxiosInstance();
  const { login } = useSetTokens();

  const handleSubmitEvent = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const loginData: LoginModel = {
      username,
      password,
      rememberMe,
    };

    if (!instanceReady) {
      console.log("Instance not ready");
      return;
    }
    if (instanceReady) {
      console.log("Instance ready");
    }

    AuthenticateService.login({
      body: loginData,
    })
      .then(login)
      .then((response) => {
        console.log("Successful Login");
        setIsLoading(false);
        navigate("/providers");
        return response;
      })
      .catch((e) => {
        console.log("Invalid Login:", e);
        setIsLoading(false);
        setLoginError("Invalid username or password.");
        // Resetting fields on error
        setUsername("");
        setPassword("");
        setRememberMe(false);
      });
  };

  return (
    <div
      className="loginWrapper"
      style={{ backgroundImage: "url(/imgs/h4m_light_bg.png)" }}
    >
      <div className="container centerflexbox">
        <Card>
          <CardBody>
            <h1>Hearts 4 Minds Administrative</h1>
            <div>Login to access your dashboard</div>
            <div>
              {loginError && (
                <div className="kendo-form-error">{loginError}</div>
              )}
              <KendoTextInput
                value={username}
                onChange={setUsername}
                placeholder="Username"
                required
                className="mt-2 border-secondary"
              />
              <KendoTextInput
                value={password}
                type="password"
                onChange={setPassword}
                placeholder="Password"
                required
                className="mt-2"
              />
              <Checkbox
                checked={rememberMe}
                label="Remember Me"
                onChange={(e: any) => setRememberMe(e.value)}
                className="mt-2"
              />
              <div className="centerflex">
                <KendoPrimaryButton
                  onClick={handleSubmitEvent}
                  text="Login"
                  disabled={isLoading}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Login;
