import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ReactElement } from "react";

interface SortDropdownProps {
  selectedSort: { text: string; value: string };
  onSortChange: (sort: { text: string; value: string }) => void;
}

function ProviderSortDropdown({
  selectedSort,
  onSortChange,
}: SortDropdownProps): ReactElement {
  const sortOptions = [
    { text: "Most Relevant", value: "relevant" },
    { text: "A-Z", value: "asc" },
    { text: "Z-A", value: "desc" },
  ];

  return (
    <div className="sortDropdown">
      <h3>Sort</h3>
      <DropDownList
        data={sortOptions}
        textField="text"
        dataItemKey="value"
        value={selectedSort}
        onChange={(e) => onSortChange(e.value)}
      />
    </div>
  );
}

export default ProviderSortDropdown;
