import { FormEvent, ReactElement, useState } from "react";
import { Answer, ProviderViewModel, Question } from "../../services/index.defs";
import KendoPrimaryButton from "../../shared/KendoPrimaryButton";
import KendoTextInput from "../../shared/KendoTextInput";
import PhoneInput from "../../shared/PhoneInput";
import AdditionalQuestions from "../../shared/Provider/AdditionalQuestions";
import StateDropdown from "../../shared/StateDropdown";
import { StateType } from "../../shared/StateType";

interface ProviderRequestFormProps {
  onSave: (provider: ProviderViewModel) => void;
  questions: Question[];
  answers: Answer[];
}

function ProviderRequestForm({
  onSave,
  questions,
  answers,
}: ProviderRequestFormProps): ReactElement {
  const [name, setName] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<StateType>({ text: "", id: "" });
  const [zip, setZip] = useState<string>("");
  const [county, setCounty] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [miMultiselect, setMiMultiselect] = useState<string[]>([]);
  const [ttMultiselect, setTtMultiselect] = useState<string[]>([]);
  const [ageMultiselect, setAgeMultiselect] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleSave = () => {
    setIsClicked(true);
    const provider: ProviderViewModel = {
      id: 0,
      name,
      address1,
      address2,
      city,
      state: state.id,
      zip,
      county,
      phone,
      email,
      website,
      approvalStatus: -1,
      isInterestForm: true,
      selectedAttributes: [],
    };
    if (miMultiselect.length > 0) {
      miMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }
    setSelectedAnswers(selectedAnswers);
    if (ageMultiselect.length > 0) {
      ageMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }

    if (ttMultiselect.length > 0) {
      ttMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }
    provider.selectedAttributes = selectedAnswers;
    if (name !== "" && city !== "" && zip !== "" && website !== "") {
      onSave(provider);
    } else {
      setIsClicked(false);
    }
  };
  const formSubmit = (e: FormEvent) => {
    e.preventDefault();
  };
  return (
    <form onSubmit={formSubmit}>
      <div>
        <div className="mt-2">
          <label>Name</label>
          <KendoTextInput
            key="name"
            value={name}
            onChange={setName}
            required
            validationMessage="This field is required"
          />
        </div>
        <div className="mt-2">
          <label>Address 1</label>
          <KendoTextInput
            key="address1"
            value={address1}
            onChange={setAddress1}
          />
        </div>
        <div className="mt-2">
          <label>Address 2</label>
          <KendoTextInput
            key="address2"
            value={address2}
            onChange={setAddress2}
          />
        </div>
        <div className="mt-2">
          <label>City</label>
          <KendoTextInput
            key="city"
            value={city}
            onChange={setCity}
            required
            validationMessage="This field is required"
          />
        </div>
        <div className="mt-2">
          <label>State</label>
          <StateDropdown key="stateDD" value={state} onChange={setState} />
        </div>
        <div className="mt-2">
          <label>Zip</label>
          <KendoTextInput
            key="zip"
            value={zip}
            onChange={setZip}
            required
            validationMessage="This field is required"
          />
        </div>
        <div className="mt-2">
          <label>County</label>
          <KendoTextInput key="county" value={county} onChange={setCounty} />
        </div>
        <div className="mt-2">
          <label>Phone</label>
          <PhoneInput key="phone" value={phone} onChange={setPhone} />
        </div>
        <div className="mt-2">
          <label>Email</label>
          <KendoTextInput
            key="email"
            value={email}
            onChange={setEmail}
            required
            validationMessage="This field is required"
          />
        </div>
        <div className="mt-2">
          <label>Website</label>
          <KendoTextInput
            key="website"
            value={website}
            onChange={setWebsite}
            required
          />
        </div>
      </div>
      <div className="mt-2">
        <h4>Additional Questions About Your Services</h4>
        <AdditionalQuestions
          questions={questions}
          answers={answers}
          miMultiselect={miMultiselect}
          setMiMultiselect={setMiMultiselect}
          ttMultiselect={ttMultiselect}
          setTtMultiselect={setTtMultiselect}
          ageMultiselect={ageMultiselect}
          setAgeMultiselect={setAgeMultiselect}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
        />
      </div>
      <div className="mt-2">
        <KendoPrimaryButton
          text="Save"
          onClick={handleSave}
          disabled={isClicked}
        />
      </div>
    </form>
  );
}

export default ProviderRequestForm;
