import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { ReactElement, useState } from "react";
import setStateFromKendoEvent from "../utils/setStateFromKendoEvent";
import { StateType } from "./StateType";
import STATES from "./USStates";

type StateDropdownProps = {
  onChange?: (value: StateType) => void;
  value: StateType;
} & Omit<ComboBoxProps, "onChange">;
function StateDropdown({ onChange, value }: StateDropdownProps): ReactElement {
  const [data, setData] = useState(STATES.slice());

  const filterData = (filter: FilterDescriptor) => {
    const statedata = STATES.slice();
    return filterBy(statedata, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setData(filterData(event.filter));
  };

  return (
    <ComboBox
      onChange={onChange && setStateFromKendoEvent(onChange)}
      data={data}
      textField="text"
      value={value}
      filterable
      placeholder="Select a State..."
      onFilterChange={filterChange}
    />
  );
}

export default StateDropdown;
