import "./index.css";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import App from "./App";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <CookiesProvider>
      <App />
    </CookiesProvider>,
  );
}
