import {
  Provider,
  ProviderViewModel,
  Response,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ProviderService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAllProviders(options: IRequestOptions = {}): Promise<Provider[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/GetAllProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllProvidersByStatus(
    params: {
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Provider[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/GetAllProvidersByStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { status: params['status'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProviderById(
    params: {
      /**  */
      providerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProviderViewModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/GetProviderById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { providerId: params['providerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addNewProvider(
    params: {
      /** requestBody */
      body?: ProviderViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/AddNewProvider';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateProvider(
    params: {
      /** requestBody */
      body?: ProviderViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/UpdateProvider';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateProviderStatus(
    params: {
      /**  */
      providerId?: number;
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/UpdateProviderStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { providerId: params['providerId'], status: params['status'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteProvider(
    params: {
      /**  */
      providerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/DeleteProvider';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { providerId: params['providerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishProvider(
    params: {
      /**  */
      providerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/PublishProvider';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { providerId: params['providerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishAllProviders(options: IRequestOptions = {}): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Provider/PublishAllProviders';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
