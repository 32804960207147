import {
  Question,
  Answer,
  Tracking,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class QuestionService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAllSurveyQuestions(options: IRequestOptions = {}): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/GetAllSurveyQuestions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllAdminQuestions(options: IRequestOptions = {}): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/GetAllAdminQuestions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllProviderQuestions(options: IRequestOptions = {}): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/GetAllProviderQuestions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAnswersForQuestion(
    params: {
      /**  */
      questionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Answer[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/GetAnswersForQuestion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { questionId: params['questionId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllAnswers(options: IRequestOptions = {}): Promise<Answer[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/GetAllAnswers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addTrackingRecord(
    params: {
      /** requestBody */
      body?: Tracking;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/AddTrackingRecord';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
