import {
  Resource,
  ResourceViewModel,
  Response,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ResourceService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAllResources(options: IRequestOptions = {}): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/GetAllResources';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllResourcesById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/GetAllResourcesById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllResourcesByStatus(
    params: {
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/GetAllResourcesByStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { status: params['status'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllResourcesByType(
    params: {
      /**  */
      resourceType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/GetAllResourcesByType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { resourceType: params['resourceType'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addNewResource(
    params: {
      /** requestBody */
      body?: ResourceViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/AddNewResource';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteResource(
    params: {
      /**  */
      resourceId?: number;
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/DeleteResource';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { resourceId: params['resourceId'], userId: params['userId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateResource(
    params: {
      /** requestBody */
      body?: Resource;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/UpdateResource';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateResourceStatus(
    params: {
      /**  */
      resourceId?: number;
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/UpdateResourceStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { resourceId: params['resourceId'], status: params['status'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadResourceImage(
    params: {
      /**  */
      resourceId?: number;
      /**  */
      image: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resource/UploadResourceImage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { resourceId: params['resourceId'] };

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
