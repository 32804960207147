import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ReactElement, useEffect, useState } from "react";
import { ProviderAttributeViewModel } from "../../services/index.defs";

interface FilterProps {
  providers: ProviderAttributeViewModel[];
  onFilterChange: (filteredProviders: ProviderAttributeViewModel[]) => void;
}

interface AttributeValueDetail {
  value: string;
  friendlyName: string;
}

interface AttributeGroup {
  values: AttributeValueDetail[];
  friendlyGroupKeyLabel: string;
}

function ProviderFilter({
  providers,
  onFilterChange,
}: FilterProps): ReactElement {
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedZips, setSelectedZips] = useState<string[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);

  const cities = Array.from(
    new Set(
      providers
        .map((provider) => provider.provider.city)
        .filter((city): city is string => Boolean(city)),
    ),
  ).sort();

  const zips = Array.from(
    new Set(
      providers
        .map((provider) => provider.provider.zip)
        .filter((zip): zip is string => Boolean(zip)),
    ),
  ).sort();

  const attributeGroups: Record<string, AttributeGroup> = {};

  const excludedGroupTypes = [
    "emergencyServices",
    "providesDiagnosis",
    "gender",
    "hasVeteranServices",
  ];

  providers.forEach((provider) => {
    provider.attributeDetails?.forEach((detail) => {
      if (detail.groupKey && detail.friendlyName) {
        const { groupKey } = detail;

        if (!attributeGroups[groupKey]) {
          attributeGroups[groupKey] = {
            values: [],
            friendlyGroupKeyLabel: detail.friendlyGroupKeyLabel || "",
          };
        }

        const currentGroup = attributeGroups[groupKey];

        provider.attributes?.forEach((attr) => {
          Object.entries(attr.algoliaValues).forEach(([key, values]) => {
            if (key === groupKey) {
              values.forEach((value: string | undefined) => {
                if (value === detail.algoliaValue) {
                  const uniqueValue = `${value}_${detail.friendlyName}`;
                  // Map into a Set
                  const uniqueValuesSet = new Set(
                    currentGroup.values.map(
                      (v) => `${v.value}_${v.friendlyName}`,
                    ),
                  );

                  // Only add unique values
                  if (!uniqueValuesSet.has(uniqueValue)) {
                    currentGroup.values.push({
                      value: value as string,
                      friendlyName: detail.friendlyName as string,
                    });
                    if (!excludedGroupTypes.includes(groupKey)) {
                      currentGroup.values.sort((a, b) =>
                        a.friendlyName.localeCompare(b.friendlyName),
                      );
                    }
                  }
                }
              });
            }
          });
        });
      }
    });
  });

  // Filter function based on selections
  useEffect(() => {
    const filteredProviders = providers.filter((provider) => {
      const cityMatch =
        selectedCities.length === 0 ||
        selectedCities.includes(provider.provider.city || "");
      const zipMatch =
        selectedZips.length === 0 ||
        selectedZips.includes(provider.provider.zip || "");
      const attributeMatch =
        (selectedAttributes.length === 0 ||
          provider.attributes?.some((attr) =>
            Object.entries(attr.algoliaValues).some(([key, values]) =>
              (values as string[]).some((value) =>
                selectedAttributes.includes(`${key}:${value}`),
              ),
            ),
          )) ??
        false;
      return cityMatch && zipMatch && attributeMatch;
    });
    onFilterChange(filteredProviders);
  }, [
    selectedCities,
    selectedZips,
    selectedAttributes,
    providers,
    onFilterChange,
  ]);

  // Handlers for checkbox
  const handleCityChange = (city: string) =>
    setSelectedCities((prev) =>
      prev.includes(city) ? prev.filter((c) => c !== city) : [...prev, city],
    );
  const handleZipChange = (zip: string) =>
    setSelectedZips((prev) =>
      prev.includes(zip) ? prev.filter((z) => z !== zip) : [...prev, zip],
    );
  const handleAttributeChange = (attributeKey: string, value: string) => {
    const attrValue = `${attributeKey}:${value}`;
    setSelectedAttributes((prev) =>
      prev.includes(attrValue)
        ? prev.filter((a) => a !== attrValue)
        : [...prev, attrValue],
    );
  };

  return (
    <div className="filter-container">
      <h3>Filters</h3>
      <PanelBar>
        <PanelBarItem title="City">
          {cities.map((city) => (
            <div key={city}>
              <input
                type="checkbox"
                id={`city-${city}`}
                checked={selectedCities.includes(city)}
                onChange={() => handleCityChange(city as string)}
              />
              <label htmlFor={`city-${city}`}>{city}</label>
            </div>
          ))}
        </PanelBarItem>
        <PanelBarItem title="Zipcode">
          {zips.map((zip) => (
            <div key={zip}>
              <input
                type="checkbox"
                id={`zip-${zip}`}
                checked={selectedZips.includes(zip)}
                onChange={() => handleZipChange(zip as string)}
              />
              <label htmlFor={`zip-${zip}`}>{zip}</label>
            </div>
          ))}
        </PanelBarItem>
        {Object.entries(attributeGroups).map(
          ([groupKey, { values, friendlyGroupKeyLabel }]) => (
            <PanelBarItem
              title={friendlyGroupKeyLabel || groupKey}
              key={groupKey}
            >
              {values.map(({ value, friendlyName }) => (
                <div key={`${groupKey}:${value}`}>
                  <input
                    type="checkbox"
                    id={`${groupKey}-${value}`}
                    checked={selectedAttributes.includes(
                      `${groupKey}:${value}`,
                    )}
                    onChange={() => handleAttributeChange(groupKey, value)}
                  />
                  <label htmlFor={`${groupKey}-${value}`}>{friendlyName}</label>
                </div>
              ))}
            </PanelBarItem>
          ),
        )}
      </PanelBar>
    </div>
  );
}

export default ProviderFilter;
