import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import { ReactElement } from "react";
import setStateFromKendoEvent from "../utils/setStateFromKendoEvent";

type PhoneInputProps = {
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
} & Omit<MaskedTextBoxProps, "onChange">;
function PhoneInput({
  onChange,
  placeholder,
  value,
}: PhoneInputProps): ReactElement {
  // Validation for incorrect characters
  /*
  const [value, setValue] = useState<string>("");
  const [valid, setValid] = useState<boolean>(true);

  let timeout;

  const updateValidity = (event) => {
    const textbox = event.target;
    if (textbox.element.value.length > textbox.value.length) {
      if (valid) {
        setValid(false);
      } else {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setValid(true);
      }, 100);
    }
  };

  const handleChange = (event: MaskedTextBoxChangeEvent) => {
    if (value === event.target.value) {
      updateValidity(event);
    } else {
      setValue(event.target.value);
    }
  };
  */

  return (
    <MaskedTextBox
      mask="(000) 000-0000"
      value={value}
      // valid={valid}
      onChange={onChange && setStateFromKendoEvent(onChange)}
      placeholder={placeholder}
    />
  );
}

export default PhoneInput;
