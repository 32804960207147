import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticateService } from "../services/AuthenticateService";
import useAuth from "./useAuth";
import useSetTokens from "./useSetTokens";

const useRequireAuth = () => {
  const { login, removeRememberMeToken } = useSetTokens();
  const { accessToken, user, rememberMeToken, username, refreshToken } =
    useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (username && (accessToken || refreshToken || rememberMeToken)) {
      return;
    }
    const gotoLogin = (): void =>
      navigate("/login", { state: { from: location } });

    if (
      !(rememberMeToken && username) &&
      (location.pathname === "/providers" ||
        location.pathname === "/resourceForm" ||
        location.pathname.startsWith("/EditProvider"))
    ) {
      gotoLogin();
      return;
    }
    if (
      username !== null &&
      username !== undefined &&
      rememberMeToken !== undefined &&
      rememberMeToken !== null
    ) {
      AuthenticateService.rememberMe({
        body: { username, token: rememberMeToken },
      })
        .then(login)
        .catch(() => {
          removeRememberMeToken();
        });
    }
  }, [accessToken, user, rememberMeToken, navigate, location]);
};

export default useRequireAuth;
