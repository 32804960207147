import { ReactElement, useEffect, useState } from "react";
import { ProviderService } from "../../services/ProviderService";
import KendoPrimaryButton from "../KendoPrimaryButton";

interface PublishCellProps {
  dataItem: {
    id: number;
    lastUpdatedDate: Date;
    publishDate: Date;
  };
  fetchProviders?: () => void;
}

function PublishCell({
  dataItem,
  fetchProviders,
}: PublishCellProps): ReactElement | null {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const shouldDisable = () => {
      const lastUpdated = new Date(dataItem.lastUpdatedDate);
      const publishDate = new Date(dataItem.publishDate);
      // Disable if publishing all or if the last update is after the last publish
      return lastUpdated <= publishDate;
    };

    setIsDisabled(shouldDisable());
  }, [dataItem]);

  const handleChange = () => {
    setIsLoading(true);
    setIsDisabled(true);
    ProviderService.publishProvider({ providerId: dataItem.id })
      .then((response) => {
        if (fetchProviders) {
          fetchProviders();
        }
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        console.error("Error publishing:", error);
        setIsDisabled(false);
        setIsLoading(false);
      });
  };

  const buttonText = isLoading ? "Publishing" : "Publish";

  return (
    <KendoPrimaryButton
      onClick={handleChange}
      text={buttonText}
      disabled={isDisabled || isLoading}
    />
  );
}

export default PublishCell;
