import {
  Card,
  CardBody,
  PanelBar,
  PanelBarItem,
} from "@progress/kendo-react-layout";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlgoliaService } from "../../services/AlgoliaService";
import { ProviderAttributeViewModel } from "../../services/index.defs";
import BrandedPrimaryButton from "../../shared/branded/BrandedPrimaryButton";
import ProviderFilter from "../../shared/Provider/ProviderFilter";
import ProviderSortDropdown from "../../shared/Provider/ProviderSortDropdown";

interface SurveryResultsProps {
  zipCode?: string;
  answers?: Array<number> | [];
}

function transformProvidersWithFriendlyNames(providers: any[]) {
  return providers.map((provider) => {
    const attributesWithFriendlyNames = provider.attributes.map(
      (attribute: { algoliaValues: { [s: string]: string[] } | any[] }) => {
        const algoliaValuesWithNames = Object.entries(
          attribute.algoliaValues,
        ).reduce<{ [key: string]: string[] }>(
          (acc, [key, values]: [string, any[]]) => {
            acc[key] = values.map((value: any) => {
              // Find the friendly name for this value.
              const detail = provider.attributeDetails.find(
                (d: { groupKey: string; algoliaValue: any }) => {
                  return d.groupKey === key && d.algoliaValue === `${value}`;
                },
              );
              return detail?.friendlyName || value;
            });
            return acc;
          },
          {},
        );

        return {
          ...attribute,
          algoliaValues: algoliaValuesWithNames,
        };
      },
    );

    return {
      ...provider,
      attributes: attributesWithFriendlyNames,
    };
  });
}

const sortProviders = (
  providers: ProviderAttributeViewModel[],
  sortBy: string,
) => {
  switch (sortBy) {
    case "asc":
      return [...providers].sort((a, b) => {
        const nameA = a.provider.name?.toLowerCase() ?? "";
        const nameB = b.provider.name?.toLowerCase() ?? "";
        return nameA.localeCompare(nameB);
      });
    case "desc":
      return [...providers].sort((a, b) => {
        const nameA = a.provider.name?.toLowerCase() ?? "";
        const nameB = b.provider.name?.toLowerCase() ?? "";
        return nameB.localeCompare(nameA);
      });
    default:
      return providers;
  }
};

function SurveryResults({
  zipCode,
  answers,
}: SurveryResultsProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [providers, setProviders] = useState<ProviderAttributeViewModel[]>([]);
  const navigate = useNavigate();

  const [filteredProviders, setFilteredProviders] = useState<
    ProviderAttributeViewModel[]
  >([]);

  const [selectedSort, setSelectedSort] = useState({
    text: "Most Relevant",
    value: "relevant",
  });

  const goBackToSurvey = () => {
    navigate("/survey");
  };

  const sortedProviders = useMemo(() => {
    if (selectedSort.value !== "relevant") {
      return sortProviders(filteredProviders, selectedSort.value);
    }
    return filteredProviders;
  }, [selectedSort, filteredProviders]);

  useEffect(() => {
    setFilteredProviders(providers);
  }, [providers]);

  useEffect(() => {
    setIsLoading(true);
    const cleanAnswers = answers
      ? answers.filter((item) => !Number.isNaN(Number(item))).map(Number)
      : [];
    AlgoliaService.filterAlgoliaIndex({ zipCode, body: cleanAnswers })
      .then((response) => {
        setProviders(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching results:", e);
        setIsLoading(false);
        setError(e);
        return e;
      });
  }, [zipCode, answers]);

  if (isLoading) {
    return (
      <div className="surveyWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Loading...</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="surveyWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Error: {error}</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }

  if (providers.length === 0) {
    return (
      <div className="surveyWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2 no-results-text-wrapper">
            <CardBody>
              <div className="resultDisclaimer">
                <p>
                  Currently, <b>Hearts 4 Minds Connect</b> offers mental health
                  resources specific to Northeast Florida.
                  <br /> We are actively working to provide information from all
                  of Florida, Georgia, and eventually the entire nation!
                  <br /> Until then, please explore these{" "}
                  <a
                    target="_blank"
                    href="https://www.hearts4minds.org/mental-health-resources#National"
                    rel="noreferrer"
                  >
                    National Resources
                  </a>{" "}
                  and crisis providers for assistance.
                </p>
              </div>
            </CardBody>
          </Card>
          <div className="mt-2 mb-2">
            <BrandedPrimaryButton
              onClick={goBackToSurvey}
              text="Start Over"
              width="200px"
              className="secondary"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="resultsShell"
      style={{
        backgroundImage: "url(/imgs/h4m_path_survey_results-with-dragon.svg)",
      }}
    >
      <div className="pt-5 container">
        <div className="resultsHeader">
          <div>
            <ProviderSortDropdown
              selectedSort={selectedSort}
              onSortChange={(sort) => setSelectedSort(sort)}
            />
          </div>
          <div>
            <BrandedPrimaryButton
              onClick={goBackToSurvey}
              text="Start A New Search"
              width="200px"
              className="secondary"
            />
          </div>
        </div>
        <div className="resultsWrapper mt-2">
          <div className="sidebar">
            <ProviderFilter
              providers={providers}
              onFilterChange={setFilteredProviders}
            />
          </div>
          <div className="resultsContainer">
            <div className="">
              {transformProvidersWithFriendlyNames(sortedProviders).map(
                (provider) => (
                  <div
                    className={`resultCard resultCard-${provider.objectID}`}
                    key={provider.objectID}
                    style={{
                      marginBottom: "20px",
                      border: "1px solid #ccc",
                      padding: "10px",
                    }}
                  >
                    <div className="providerDetails">
                      <div className="providerLeftside">
                        <h2>
                          {provider.provider.name !== undefined
                            ? provider.provider.name.trim()
                            : ""}
                        </h2>
                        <p>
                          <b>Address:</b>{" "}
                          {provider.provider.address1 !== undefined
                            ? provider.provider.address1.trim()
                            : ""}
                          {provider.provider.address2 !== undefined &&
                          provider.provider.address2.trim() !== "" ? (
                            <span> {provider.provider.address2.trim()}, </span>
                          ) : (
                            ""
                          )}
                          {provider.provider.city !== undefined ? (
                            <span> {provider.provider.city.trim()}, </span>
                          ) : (
                            ""
                          )}
                          {provider.provider.state !== undefined
                            ? provider.provider.state.trim()
                            : ""}{" "}
                          {provider.provider.zip !== undefined
                            ? provider.provider.zip.trim()
                            : ""}
                        </p>
                        <p>
                          <b>County:</b>{" "}
                          {provider.provider.county !== undefined
                            ? provider.provider.county.trim()
                            : ""}
                        </p>
                      </div>
                      <div className="providerRightside">
                        <p>
                          <b>
                            <i className="fa-solid fa-circle-phone" />
                          </b>{" "}
                          <a
                            className="number"
                            href={`tel:${
                              provider.provider.phone !== undefined
                                ? provider.provider.phone.trim()
                                : ""
                            }`}
                          >
                            {provider.provider.phone || "Not Listed"}
                          </a>
                        </p>
                        <p>
                          <b>
                            <i className="fa-solid fa-globe-pointer" />
                          </b>{" "}
                          <a
                            href={
                              provider.provider.website !== undefined
                                ? provider.provider.website.trim()
                                : ""
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Visit Website
                          </a>
                        </p>
                      </div>
                    </div>
                    {provider.attributes && provider.attributes.length > 0 && (
                      <PanelBar
                        expandMode="multiple"
                        className="providerAttributes"
                      >
                        <PanelBarItem title="Provider Details">
                          <div>
                            {provider.attributes.map(
                              (attribute: {
                                algoliaValues:
                                  | { [s: string]: string[] }
                                  | string;
                              }) => {
                                return (
                                  <div
                                    className="attributeWrapper"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    {Object.entries(
                                      attribute.algoliaValues,
                                    ).map(([key, value]) => {
                                      switch (key) {
                                        case "age":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Age Groups</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "providesDiagnosis":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Provides Diagnosis</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "hasVeteranServices":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Veteran Services</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "medicalIssue":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Medical Issues Treated</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "careType":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Care Type</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "treatmentType":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Treatment Type</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        case "emergencyServices":
                                          return (
                                            <div className="attributePillsWrapper">
                                              <div>Emergency Services</div>
                                              <div className="attributePills">
                                                {value.map(
                                                  (friendlyName: string) => {
                                                    return (
                                                      <div className="answerPill">
                                                        {friendlyName}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </div>
                                          );
                                        default:
                                          return "";
                                      }
                                    })}
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </PanelBarItem>
                      </PanelBar>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveryResults;
