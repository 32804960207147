import { ReactElement } from "react";
import { ProviderService } from "../../services/ProviderService";
import ApprovalStatusChipList from "../ApprovalStatusChipList";

interface ApprovalStatusChipSetCellProps {
  dataItem: {
    id: number;
    approvalStatus: number;
  };
  fetchProviders?: () => void;
}

function ApprovalStatusChipSetCell({
  dataItem,
  fetchProviders,
}: ApprovalStatusChipSetCellProps): ReactElement | null {
  const providerId = dataItem.id;
  const handleChange = (status: number) => {
    ProviderService.updateProviderStatus({
      providerId,
      status,
    })
      .then((response) => {
        if (fetchProviders) {
          fetchProviders();
        }
        return response;
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        return error;
      });
  };
  return (
    <td>
      <ApprovalStatusChipList
        chipListValue={dataItem.approvalStatus}
        setChipListValue={handleChange}
      />
    </td>
  );
}

export default ApprovalStatusChipSetCell;
