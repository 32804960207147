import { pencilIcon } from "@progress/kendo-svg-icons";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import BrandedPrimaryButton from "../branded/BrandedPrimaryButton";

interface EditProviderCellProps {
  dataItem: {
    id: number;
  };
}

function EditProviderCell({
  dataItem,
}: EditProviderCellProps): ReactElement | null {
  const navigate = useNavigate();
  const handleEdit = (): void => {
    navigate(`/EditProvider/${dataItem.id}`);
  };

  return (
    <td>
      <BrandedPrimaryButton
        text=""
        onClick={handleEdit}
        icon={pencilIcon}
        className="secondary"
        width="75px"
      />
    </td>
  );
}

export default EditProviderCell;
