import { ReactElement } from "react";
import { Answer, Question } from "../../services/index.defs";
import KendoChipList from "../KendoChipList";

interface AdditionalQuestionsProps {
  questions: Question[];
  answers: Answer[];
  miMultiselect: string[];
  setMiMultiselect: (mi: string[]) => void;
  ttMultiselect: string[];
  setTtMultiselect: (tt: string[]) => void;
  ageMultiselect: string[];
  setAgeMultiselect: (age: string[]) => void;
  selectedAnswers: number[];
  setSelectedAnswers: (ans: number[]) => void;
}

function AdditionalQuestions({
  questions,
  answers,
  miMultiselect,
  setMiMultiselect,
  ttMultiselect,
  setTtMultiselect,
  ageMultiselect,
  setAgeMultiselect,
  selectedAnswers,
  setSelectedAnswers,
}: AdditionalQuestionsProps): ReactElement {
  return (
    <div key="survey mt-2">
      {questions
        .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
        .map((item) => {
          if (item.questionType === "mimultiselect") {
            const miResults = answers.filter(
              (answer) => answer.questionId === item.id,
            );
            return (
              <div>
                <span>{item.questionText}</span>
                <KendoChipList
                  question={item}
                  answers={miResults}
                  chipListValue={miMultiselect}
                  setChipListValue={setMiMultiselect}
                  selType="mi"
                  selection="multiple"
                />
              </div>
            );
          }
          if (item.questionType === "ttmultiselect") {
            const ttResults = answers.filter(
              (answer) => answer.questionId === item.id,
            );
            return (
              <div>
                <span>{item.questionText}</span>
                <KendoChipList
                  question={item}
                  answers={ttResults}
                  chipListValue={ttMultiselect}
                  setChipListValue={setTtMultiselect}
                  selType="mi"
                  selection="multiple"
                />
              </div>
            );
          }
          if (item.questionType === "agemultiselect") {
            const ageResults = answers.filter(
              (answer) => answer.questionId === item.id,
            );
            return (
              <div>
                <span>{item.questionText}</span>
                <KendoChipList
                  question={item}
                  answers={ageResults}
                  chipListValue={ageMultiselect}
                  setChipListValue={setAgeMultiselect}
                  selType="mi"
                  selection="multiple"
                />
              </div>
            );
          }
          if (item.questionType === "boolean") {
            const boolResults = answers.filter(
              (answer) => answer.questionId === item.id,
            );
            return (
              <div>
                <span>{item.questionText}</span>
                <KendoChipList
                  question={item}
                  answers={boolResults}
                  chipListValue={selectedAnswers}
                  setChipListValue={setSelectedAnswers}
                  selection="single"
                  selType="boolean"
                />
              </div>
            );
          }
          return "";
        })}
    </div>
  );
}

export default AdditionalQuestions;
