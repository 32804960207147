/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export interface AlgoliaAttributeDetailViewModel {
  /**  */
  algoliaValues: object;
}

export interface Answer {
  /**  */
  id: number;

  /**  */
  questionId: number;

  /**  */
  answerText?: string;

  /**  */
  queryParameter?: string;

  /**  */
  sequence: number;

  /**  */
  attributeId: number;

  /**  */
  hasMultipleAttributes: boolean;

  /**  */
  altQuestionSequence?: number;

  /**  */
  altQuestionId?: number;
}

export interface AttributeDetail {
  /**  */
  id: number;

  /**  */
  groupKey?: string;

  /**  */
  algoliaId: number;

  /**  */
  algoliaValue?: string;

  /**  */
  friendlyName?: string;

  /**  */
  friendlyGroupKeyLabel?: string;
}

export interface ForgotPasswordModel {
  /**  */
  username: string;
}

export interface LoginModel {
  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  rememberMe: boolean;
}

export interface LoginResult {
  /**  */
  accessToken: string;

  /**  */
  refreshToken: string;

  /**  */
  rememberMeToken?: string;
}

export interface Provider {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zip?: string;

  /**  */
  county?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  website?: string;

  /**  */
  approvalStatus: number;

  /**  */
  isDeleted: boolean;

  /**  */
  createdBy: number;

  /**  */
  createdDate: Date;

  /**  */
  lastUpdatedBy: number;

  /**  */
  lastUpdatedDate: Date;

  /**  */
  publishBy: number;

  /**  */
  publishDate: Date;

  /**  */
  isPublished: boolean;
}

export interface ProviderAttributeDetail {
  /**  */
  id: number;

  /**  */
  providerId: number;

  /**  */
  attributeId: number;
}

export interface ProviderAttributeViewModel {
  /**  */
  provider: Provider;

  /**  */
  attributes?: AlgoliaAttributeDetailViewModel[];

  /**  */
  attributeDetails?: AttributeDetail[];

  /**  */
  objectID?: string;
}

export interface ProviderViewModel {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zip?: string;

  /**  */
  county?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  website?: string;

  /**  */
  approvalStatus: number;

  /**  */
  selectedAttributes?: number[];

  /**  */
  allBooleanAnswers?: number[];

  /**  */
  allMiAnswers?: number[];

  /**  */
  allTtAnswers?: number[];

  /**  */
  allAgeAnswers?: number[];

  /**  */
  isInterestForm: boolean;
}

export interface Question {
  /**  */
  id: number;

  /**  */
  questionText?: string;

  /**  */
  sequence: number;

  /**  */
  groupKey?: string;

  /**  */
  isProviderIntake: boolean;

  /**  */
  isFilterQuestion: boolean;

  /**  */
  isAdminForm: boolean;

  /**  */
  questionType: string;

  /**  */
  additionalInBoxText: string;

  /**  */
  additionalPageTopText: string;

  /**  */
  additionalPageBottomText: string;

  /**  */
  hasAlternativePath: boolean;

  /**  */
  isOptionalFilterQuestion: boolean;
}

export interface ResetPasswordModel {
  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  token: string;
}

export interface Resource {
  /**  */
  id: number;

  /**  */
  resourceType: number;

  /**  */
  resourceName?: string;

  /**  */
  description?: string;

  /**  */
  url?: string;

  /**  */
  blobPath?: string;

  /**  */
  approvalStatus: number;

  /**  */
  isDeleted: boolean;

  /**  */
  createdBy: number;

  /**  */
  lastUpdatedBy: number;
}

export interface ResourceAttributeDetail {
  /**  */
  id: number;

  /**  */
  resourceId: number;

  /**  */
  attributeId: number;
}

export interface ResourceViewModel {
  /**  */
  resourceType?: number;

  /**  */
  resourceName?: string;

  /**  */
  description?: string;

  /**  */
  url?: string;

  /**  */
  blobPath?: string;

  /**  */
  approvalStatus?: number;
}

export interface Response {
  /**  */
  success: boolean;

  /**  */
  status?: string;

  /**  */
  message?: string;

  /**  */
  id: number;
}

export interface TokenModel {
  /**  */
  username: string;

  /**  */
  token: string;
}

export interface Tracking {
  /**  */
  zipCode?: string;

  /**  */
  answersJson?: string;
}
