import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../services/index.defs";
import { ProviderService } from "../../services/ProviderService";
import KendoPrimaryButton from "../KendoPrimaryButton";
import LogoutButton from "../LogoutButton";
// import ApprovalStatusCell from "./ApprovalStatusCell";
import ApprovalStatusChipSetCell from "./ApprovalStatusChipSetCell";
import EditProviderCell from "./EditProviderCell";
import PublishCell from "./PublishCell";

function PublishButton(fetchProviders: () => void) {
  return function PublishButtonCell(props: any) {
    const dataItem = props;
    return (
      <PublishCell
        dataItem={dataItem.dataItem}
        fetchProviders={fetchProviders}
      />
    );
  };
}
/*
function ApprovalSwitch(fetchProviders: () => void) {
  return function ApprovalSwitchCell(props: any) {
    const dataItem = props;
    console.log("dataItem: ", dataItem);
    return (
      <ApprovalStatusCell
        dataItem={dataItem.dataItem}
        fetchProviders={fetchProviders}
      />
    );
  };
}
*/
function ApprovalStatus(fetchProviders: () => void) {
  return function ApprovalStatusChipSet(props: any) {
    const dataItem = props;
    return (
      <ApprovalStatusChipSetCell
        dataItem={dataItem.dataItem}
        fetchProviders={fetchProviders}
      />
    );
  };
}

function ProviderTable(): ReactElement {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [data, setData] = useState<Provider[]>(providers);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const filterData = (e: { target: { value: any } }) => {
    const { value } = e.target;
    setData(
      filterBy(providers, {
        logic: "and",
        filters: [
          { field: "name", operator: "contains", value, ignoreCase: true },
        ],
      }),
    );
  };

  const fetchProviders = useCallback(() => {
    // setLoading(true);
    ProviderService.getAllProviders()
      .then((response) => {
        setProviders(response);
        setData(response);
        // setLoading(false);
        return response;
      })
      .catch((e) => {
        setError(e);
        // setLoading(false);
      });
  }, []);

  const handlePublishAll = () => {
    setLoading(true);
    ProviderService.publishAllProviders()
      .then((response) => {
        setLoading(false);
        fetchProviders();
        return response;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  const PublishButtonCell = PublishButton(fetchProviders);
  const ApprovalSwitchCell = ApprovalStatus(fetchProviders);

  if (loading)
    return (
      <div className="providerTableWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Loading...</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="providerTableWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Error Loading Providers...</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );

  return (
    <div className="providerTableWrapper centerflexbox">
      <div className="container-full">
        <Card>
          <CardHeader>
            <KendoPrimaryButton
              text="Add a New Provider"
              onClick={() => navigate("/AddNewProvider")}
            />
            <LogoutButton />
          </CardHeader>
          <CardBody>
            <Grid data={data} className="providerGrid">
              <GridToolbar>
                <Input onChange={filterData} />
                <KendoPrimaryButton
                  text="Publish All"
                  onClick={handlePublishAll}
                />
              </GridToolbar>
              <GridColumn field="" title="" cell={EditProviderCell} />
              <GridColumn field="name" title="Name" />
              <GridColumn field="address1" title="Address 1" />
              <GridColumn field="city" title="City" />
              <GridColumn
                field="approvalStatus"
                title="Approval Status"
                cell={ApprovalSwitchCell}
              />
              <GridColumn
                field="Publish"
                title="Publish"
                cell={PublishButtonCell}
              />
            </Grid>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ProviderTable;
