import { UploadFileInfo, UploadOnAddEvent } from "@progress/kendo-react-upload";
import { ReactElement, useEffect, useState } from "react";
import KendoUpload from "../KendoUpload";

interface ResourceUploadWithPreviewProps {
  onFilesChange: (files: Array<UploadFileInfo>) => void;
}

function ResourceUploadWithPreview({
  onFilesChange,
}: ResourceUploadWithPreviewProps): ReactElement {
  const [uploadedFiles, setUploadedFiles] = useState<UploadFileInfo[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  useEffect(() => {
    // Pass the uploaded files back to the parent component
    onFilesChange(uploadedFiles);
  }, [uploadedFiles, onFilesChange]);

  const onAdd = (event: UploadOnAddEvent) => {
    const newFiles = event.affectedFiles;
    const newPreviews = newFiles.map((file: UploadFileInfo) => {
      const rawFile =
        typeof file.getRawFile === "function" ? file.getRawFile() : null;
      return rawFile ? URL.createObjectURL(rawFile) : "";
    });

    setUploadedFiles((currentFiles) => [...currentFiles, ...newFiles]);
    setPreviews((currentPreviews) => [...currentPreviews, ...newPreviews]);
  };

  const onRemove = () => {
    /* const removedFiles = event.affectedFiles;
    const updatedFiles = uploadedFiles.filter(
      (file) => !removedFiles.includes(file),
    );
    const updatedPreviews = updatedFiles.map((file) =>
      file.getRawFile() ? URL.createObjectURL(file.getRawFile()) : "",
    );
    */

    setUploadedFiles([]);
    setPreviews([]);
  };

  useEffect(() => {
    return () => {
      previews.forEach((preview) => {
        if (preview) {
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [previews]);

  const getFileKey = (file: UploadFileInfo) => {
    return `${file.name}-${file.uid}`;
  };

  return (
    <div>
      <KendoUpload
        batch={false}
        multiple={false}
        files={uploadedFiles}
        onAdd={onAdd}
        onRemove={onRemove}
      />
      <div style={{ marginTop: "20px" }}>
        {uploadedFiles.map((file, index) => {
          const preview = previews[index];
          return (
            preview && (
              <div key={getFileKey(file)} style={{ marginBottom: "10px" }}>
                <img
                  src={preview}
                  alt={file.name}
                  style={{ width: 100, height: 100 }}
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default ResourceUploadWithPreview;
