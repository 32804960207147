import { Button } from "@progress/kendo-react-buttons";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { MouseEvent, ReactElement } from "react";

interface KendoPrimaryButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  text: string;
  disabled?: boolean;
  icon?: SVGIcon;
}
function KendoPrimaryButton({
  onClick,
  text,
  disabled,
  icon,
}: KendoPrimaryButtonProps): ReactElement {
  return (
    <Button
      svgIcon={icon}
      onClick={onClick}
      disabled={disabled}
      themeColor="primary"
      className="h4mPrimaryButton"
    >
      {text}
    </Button>
  );
}

export default KendoPrimaryButton;
