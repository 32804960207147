import {
  LoginModel,
  LoginResult,
  TokenModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AuthenticateService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Authenticate/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refresh(
    params: {
      /** requestBody */
      body?: TokenModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Authenticate/refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rememberMe(
    params: {
      /** requestBody */
      body?: TokenModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Authenticate/rememberMe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Authenticate/forgotPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Authenticate/resetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
