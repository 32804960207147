import {
  ProviderAttributeViewModel,
  Provider,
  AlgoliaAttributeDetailViewModel,
  AttributeDetail,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AlgoliaService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static updateAlgoliaIndex(
    params: {
      /** requestBody */
      body?: ProviderAttributeViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Algolia/UpdateAlgoliaIndex';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addAlgoliaIndex(
    params: {
      /** requestBody */
      body?: ProviderAttributeViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Algolia/AddAlgoliaIndex';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static filterAlgoliaIndex(
    params: {
      /**  */
      zipCode?: string;
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProviderAttributeViewModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Algolia/FilterAlgoliaIndex';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { zipCode: params['zipCode'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
