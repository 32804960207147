import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import useAxiosInstance from "./auth/useAxiosInstance";
import useRequireAuth from "./auth/useRequireAuth";
import Home from "./router/Home";
import Login from "./router/login/Login";
import ProviderEdit from "./router/provider/ProviderEdit";
import ProviderRequestForm from "./router/provider/ProviderRequest";
import Survey from "./router/survey/Survey";
import AddNewProvider from "./shared/Provider/AddNewProvider";
import ProviderTable from "./shared/Provider/ProviderTable";
import ResourceForm from "./shared/resource/ResourceForm";

function AppRoutes(): ReactElement | null {
  useRequireAuth();
  const instanceReady = useAxiosInstance();

  if (!instanceReady) {
    return null;
  }

  return (
    (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/survey" element={<Home />} />
        <Route path="/survey/:zip" element={<Survey />} />
        <Route path="/login" element={<Login />} />
        <Route path="/providers" element={<ProviderTable />} />
        <Route path="/editprovider/:providerId" element={<ProviderEdit />} />
        <Route path="/resourceform" element={<ResourceForm />} />
        <Route path="/addnewprovider" element={<AddNewProvider />} />
        <Route path="/providerrequest" element={<ProviderRequestForm />} />
      </Routes>
    ) || null
  );
}

export default AppRoutes;
