import { Button } from "@progress/kendo-react-buttons";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { CSSProperties, MouseEvent, ReactElement } from "react";

interface KendoPrimaryButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  text: string;
  disabled?: boolean;
  icon?: SVGIcon;
  width: string;
  className?: string;
}
function BrandedPrimaryButton({
  onClick,
  text,
  disabled,
  icon,
  width = "100%",
  className,
}: KendoPrimaryButtonProps): ReactElement {
  const dynamicStyles = {
    "--input-width": width,
  } as CSSProperties;

  return (
    <Button
      style={dynamicStyles}
      svgIcon={icon}
      onClick={onClick}
      disabled={disabled}
      className={`brandedPrimaryButton ${className}`}
    >
      {text}
    </Button>
  );
}

export default BrandedPrimaryButton;
