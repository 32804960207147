import { ChipList } from "@progress/kendo-react-buttons";
import { ReactElement } from "react";
import setStateFromKendoEvent from "../utils/setStateFromKendoEvent";

const approvalStatusOptions = [
  {
    text: "Pending",
    value: -1,
  },
  {
    text: "Approved",
    value: 1,
  },
  {
    text: "Rejected",
    value: 2,
  },
];

interface ApprovalStatusChipListProps {
  chipListValue: number;
  setChipListValue: (s: number) => void;
}
function ApprovalStatusChipList({
  chipListValue,
  setChipListValue,
}: ApprovalStatusChipListProps): ReactElement {
  return (
    <ChipList
      id="approvalStatusChips"
      data={approvalStatusOptions}
      selection="single"
      value={chipListValue}
      onChange={setChipListValue && setStateFromKendoEvent(setChipListValue)}
    />
  );
}

export default ApprovalStatusChipList;
