import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Answer, ProviderViewModel, Question } from "../../services/index.defs";
import { ProviderService } from "../../services/ProviderService";
import { QuestionService } from "../../services/QuestionService";
import ApprovalStatusChipList from "../ApprovalStatusChipList";
import BrandedPrimaryButton from "../branded/BrandedPrimaryButton";
import KendoPrimaryButton from "../KendoPrimaryButton";
import KendoTextInput from "../KendoTextInput";
import PhoneInput from "../PhoneInput";
import StateDropdown from "../StateDropdown";
import { StateType } from "../StateType";
import STATES from "../USStates";
import AdditionalQuestions from "./AdditionalQuestions";

interface EditProviderProps {
  providerId: number;
}

function EditProviderForm({ providerId }: EditProviderProps): ReactElement {
  const [name, setName] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<StateType>({ text: "", id: "" });
  const [county, setCounty] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [approvalStatus, setApprovalStatus] = useState<number>(0);
  const [miMultiselect, setMiMultiselect] = useState<string[]>([]);
  const [ttMultiselect, setTtMultiselect] = useState<string[]>([]);
  const [ageMultiselect, setAgeMultiselect] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    ProviderService.getProviderById({ providerId })
      .then((response) => {
        setName(response.name || "");
        setAddress1(response.address1 || "");
        setAddress2(response.address2 || "");
        setCity(response.city || "");
        setState(
          STATES.find((st) => st.id === response.state) || { text: "", id: "" },
        );
        setCounty(response.county || "");
        setZip(response.zip || "");
        setPhone(response.phone || "");
        setEmail(response.email || "");
        setWebsite(response.website || "");
        setApprovalStatus(response.approvalStatus || -1);
        setMiMultiselect(response.allMiAnswers?.map(String) || []);
        setTtMultiselect(response.allTtAnswers?.map(String) || []);
        setAgeMultiselect(response.allAgeAnswers?.map(String) || []);
        setSelectedAnswers(response.allBooleanAnswers || []);
        return response;
      })
      .catch((error) => {
        console.error("Error fetching provider:", error);
      });
  }, [providerId]);
  useEffect(() => {
    QuestionService.getAllAdminQuestions()
      .then((response) => {
        setQuestions(response);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching questions:", e);
        return e;
      });
    QuestionService.getAllAnswers()
      .then((response) => {
        setAnswers(response);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching answers:", e);
        return e;
      });
  }, []);

  const handleSave = () => {
    setIsClicked(true);
    const vm: ProviderViewModel = {
      id: providerId,
      name,
      address1,
      address2,
      city,
      state: state.id,
      county,
      zip,
      phone,
      email,
      website,
      approvalStatus,
      isInterestForm: false,
      selectedAttributes: [],
    };
    if (miMultiselect.length > 0) {
      miMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }
    setSelectedAnswers(selectedAnswers);
    if (ageMultiselect.length > 0) {
      ageMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }

    if (ttMultiselect.length > 0) {
      ttMultiselect.map((selA: string) => {
        return selectedAnswers.push(parseInt(selA, 10));
      });
    }
    vm.selectedAttributes = selectedAnswers;
    if (
      vm.name !== "" &&
      vm.city !== "" &&
      vm.zip !== "" &&
      vm.website !== ""
    ) {
      ProviderService.updateProvider({ body: vm })
        .then((response) => {
          console.log("Provider updated", response);
          window.scrollTo(0, 0);
          setMessage("Provider was updated successfully.");
          setMessageType("success");
          return response;
        })
        .catch((error) => {
          console.error("Error updating provider:", error);
          window.scrollTo(0, 0);
          setMessage("There was an issue editing this provider.");
          setMessageType("error");
          setIsClicked(false);
        });
    }
    setIsClicked(false);
  };
  const formSubmit = (e: FormEvent) => {
    e.preventDefault();
  };
  if (!name)
    return (
      <div className="providerWrapper">
        <div className="container centerflexbox flexdircol">
          <Card className="mt-2">
            <CardBody>
              <div>Loading...</div>
            </CardBody>
          </Card>
        </div>
      </div>
    );

  return (
    <div className="providerWrapper">
      <div className="container-full centerflexbox flexdircol">
        <Card style={{ width: "100%", maxWidth: "1280px" }}>
          <CardHeader>
            <div>
              <h4>Edit Provider</h4>
            </div>
            <div>
              <BrandedPrimaryButton
                text="Go Back to Providers"
                onClick={() => navigate("/providers")}
                width="100px"
                className="secondary"
              />
            </div>
          </CardHeader>
          <CardBody>
            {message !== "" && <div className={messageType}>{message}</div>}
            <form onSubmit={formSubmit}>
              <div>
                <div className="mt-2">
                  <label htmlFor="name">Name</label>
                  <KendoTextInput
                    key="name"
                    value={name}
                    onChange={setName}
                    required
                    validationMessage="This field is required"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="address1">Address 1</label>
                  <KendoTextInput
                    key="address1"
                    value={address1}
                    onChange={setAddress1}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="address2">Address 2</label>
                  <KendoTextInput
                    key="address2"
                    value={address2}
                    onChange={setAddress2}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="city">Ciy</label>
                  <KendoTextInput
                    key="city"
                    value={city}
                    onChange={setCity}
                    required
                    validationMessage="This field is required"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="stateDD">State</label>
                  <StateDropdown
                    key="stateDD"
                    value={state}
                    onChange={setState}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="zip">Zip</label>
                  <KendoTextInput
                    key="zip"
                    value={zip}
                    onChange={setZip}
                    required
                    validationMessage="This field is required"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="county">County</label>
                  <KendoTextInput
                    key="county"
                    value={county}
                    onChange={setCounty}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="phone">Phone</label>
                  <PhoneInput key="phone" value={phone} onChange={setPhone} />
                </div>
                <div className="mt-2">
                  <label htmlFor="email">Email</label>
                  <KendoTextInput
                    key="email"
                    value={email}
                    onChange={setEmail}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="website">Website</label>
                  <KendoTextInput
                    key="website"
                    value={website}
                    onChange={setWebsite}
                    placeholder="Website"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="status">Status</label>
                  <ApprovalStatusChipList
                    key="status"
                    chipListValue={approvalStatus}
                    setChipListValue={setApprovalStatus}
                  />
                </div>
              </div>
              <div className="mt-2">
                <h4>Additional Questions About Providers Services</h4>
                <AdditionalQuestions
                  questions={questions}
                  answers={answers}
                  miMultiselect={miMultiselect}
                  setMiMultiselect={setMiMultiselect}
                  ttMultiselect={ttMultiselect}
                  setTtMultiselect={setTtMultiselect}
                  ageMultiselect={ageMultiselect}
                  setAgeMultiselect={setAgeMultiselect}
                  selectedAnswers={selectedAnswers}
                  setSelectedAnswers={setSelectedAnswers}
                />
              </div>
              <div className="mt-2">
                <KendoPrimaryButton
                  text="Save"
                  onClick={handleSave}
                  disabled={isClicked}
                />
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default EditProviderForm;
