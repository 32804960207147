import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { AuthenticateService } from "../services/AuthenticateService";
import {
  LoginResult,
  serviceOptions,
  TokenModel,
} from "../services/index.defs";
import useSetTokens from "./useSetTokens";
import useTokens from "./useTokens";

const useAxiosInstance = (): boolean => {
  const [instanceReady, setInstanceReady] = useState(false);
  const { login, logout } = useSetTokens();
  const { accessToken, refreshToken, username } = useTokens();

  useLayoutEffect(() => {
    function setInstance(): void {
      serviceOptions.axios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: accessToken
          ? { Authorization: `Bearer ${accessToken}` }
          : undefined,
      });
      setInstanceReady(true);
    }

    if (!accessToken && refreshToken && username) {
      const url = "api/Authenticate/refresh";

      axios
        .post<LoginResult>(
          url,
          { username, token: refreshToken },
          { baseURL: process.env.REACT_APP_BASE_URL },
        )
        .then((result) => {
          const { data } = result;
          login(data);
          return result;
        })
        .finally(() => {
          setInstance();
        })
        .catch(logout);
    } else {
      setInstance();
    }

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;
          try {
            if (username && refreshToken) {
              const tokenModel: TokenModel =
                {
                  username,
                  token: refreshToken,
                } || undefined;
              const loginResult = await AuthenticateService.refresh({
                body: tokenModel,
              });
              localStorage.setItem("accessToken", loginResult.accessToken);
              localStorage.setItem("refreshToken", loginResult.refreshToken);
              axios.defaults.headers.common.Authorization = `Bearer ${loginResult.accessToken}`;
              originalRequest.headers.Authorization = `Bearer ${loginResult.accessToken}`;
              setInstanceReady(true);
              return axios(originalRequest);
            }
          } catch (refreshError) {
            // Handle the case where the refresh token is also invalid/expired
            console.error("Unable to refresh token", refreshError);
            // Redirect to login or perform other cleanup actions
            return Promise.reject(refreshError);
          }
        }
        console.error("No Refresh token zone: ", error);
        return Promise.reject(error);
      },
    );
  }, [accessToken, refreshToken, username]);

  return instanceReady;
};

export default useAxiosInstance;
