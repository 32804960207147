import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import EditProviderForm from "../../shared/Provider/EditProviderForm";

function ProviderEdit(): ReactElement {
  const params = useParams();
  const providerId = params.providerId ? parseInt(params.providerId, 10) : 0;

  return <EditProviderForm providerId={providerId} />;
}

export default ProviderEdit;
