import { useNavigate } from "react-router-dom";
import useSetTokens from "../auth/useSetTokens";
import KendoPrimaryButton from "./KendoPrimaryButton";

function LogoutButton() {
  const { logout } = useSetTokens();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return <KendoPrimaryButton onClick={handleLogout} text="Logout" />;
}

export default LogoutButton;
