import { Input, InputProps } from "@progress/kendo-react-inputs";
import { ReactElement } from "react";
import setStateFromKendoEvent from "../utils/setStateFromKendoEvent";

type KendoInputProps = {
  onChange?: (value: string) => void;
} & Omit<InputProps, "onChange">;

function KendoTextInput({ onChange, ...props }: KendoInputProps): ReactElement {
  return (
    <Input {...props} onChange={onChange && setStateFromKendoEvent(onChange)} />
  );
}

export default KendoTextInput;
