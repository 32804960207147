import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ReactElement } from "react";
import setStateFromKendoEvent from "../utils/setStateFromKendoEvent";

interface KendoDropdownListProps {
  onChange?: (e: { text: string; id: string }) => void;
  data: Array<{ text: string; id: string }>;
  defaultItem?: { text: string };
  style?: object;
  textField: string;
  value?: { text: string; id: string };
  dataItemKey?: string;
  key?: string;
}
function KendoDropdownList({
  onChange,
  data,
  defaultItem,
  style,
  textField,
  value,
  dataItemKey,
  key,
}: KendoDropdownListProps): ReactElement {
  return (
    <DropDownList
      onChange={onChange && setStateFromKendoEvent(onChange)}
      data={data}
      textField={textField}
      defaultItem={defaultItem}
      style={style}
      value={value}
      dataItemKey={dataItemKey}
      key={key}
    />
  );
}

export default KendoDropdownList;
