import useTokens from "./useTokens";

type AuthHeader = { Authorization?: string };

const useAuthHeader = (): AuthHeader => {
  const { accessToken } = useTokens();

  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` };
  }
  return {};
};

export default useAuthHeader;
