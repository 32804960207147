import { useContext } from "react";
import authContext, { AuthContext } from "./authContext";

type UseSetTokens = Pick<
  AuthContext,
  "login" | "logout" | "removeRememberMeToken"
>;

const useSetTokens = (): UseSetTokens => {
  const { login, logout, removeRememberMeToken } = useContext(authContext);

  return {
    login,
    logout,
    removeRememberMeToken,
  };
};

export default useSetTokens;
