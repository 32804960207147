const REFRESH_TOKEN_KEY = "refreshToken";
const REMEMBER_ME_TOKEN_KEY = "rememberMeToken";
const USERNAME_KEY = "username";

export function storageLogout(): void {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REMEMBER_ME_TOKEN_KEY);
  localStorage.removeItem(USERNAME_KEY);
}

function setItem(key: string, token: string | null): void {
  if (!token) {
    return;
  }
  localStorage.setItem(key, token);
}

function getItem(key: string): string | null {
  return localStorage.getItem(key);
}

export function setStorageUsername(username: string | null): void {
  setItem(USERNAME_KEY, username);
}

export function getStorageUsername(): string | null {
  return getItem(USERNAME_KEY);
}

export function setStorageRememberMeToken(token: string | null): void {
  setItem(REMEMBER_ME_TOKEN_KEY, token);
}

export function removeStorageRememberMeToken(): void {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}
export function getStorageRememberMeToken(): string | null {
  return getItem(REMEMBER_ME_TOKEN_KEY);
}

export function setStorageRefreshToken(token: string | null): void {
  return setItem(REFRESH_TOKEN_KEY, token);
}

export function getStorageRefreshToken(): string | null {
  return getItem(REFRESH_TOKEN_KEY);
}
