import { createContext } from "react";
import { LoginResult } from "../services/index.defs";
import { User } from "./User";

export type AuthContext = {
  accessToken: string | undefined | null;
  login: (loginResult: LoginResult) => void;
  logout: () => void;
  refreshToken: string | undefined | null;
  rememberMeToken: string | undefined | null;
  removeRememberMeToken: () => void;
  user: User | null;
  username: string | undefined | null;
};

const authContext = createContext<AuthContext>({
  accessToken: undefined,
  login: () => {
    throw new Error("AuthProvider not available for Login");
  },
  logout: () => {
    throw new Error("AuthProvider not available for Logout: ");
  },
  refreshToken: undefined,
  rememberMeToken: undefined,
  removeRememberMeToken: () => {
    throw new Error("AuthProvider not available for Remember Me");
  },
  user: null,
  username: undefined,
});

export default authContext;
