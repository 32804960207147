import { Button } from "@progress/kendo-react-buttons";
import {
  Card,
  CardBody,
  Step,
  Stepper,
  StepperChangeEvent,
  StepProps,
} from "@progress/kendo-react-layout";
import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Answer, Question, Tracking } from "../../services/index.defs";
import { QuestionService } from "../../services/QuestionService";
import BrandedPrimaryButton from "../../shared/branded/BrandedPrimaryButton";
import KendoChipList from "../../shared/KendoChipList";
import SurveyResults from "./SurveyResults";

// Tracking
const useSurveyTracker = (selectedAnswers: number[], zipCode: string) => {
  const trackProgress = async () => {
    // Structure the data to be sent
    const trackingData: Tracking = {
      zipCode,
      answersJson: JSON.stringify(selectedAnswers),
    };

    // Function to send the data
    const sendAnalyticsData = async (tracking: Tracking) => {
      const url = `${process.env.REACT_APP_BASE_URL}api/Question/AddTrackingRecord`;
      if (navigator.sendBeacon) {
        const dataBlob = new Blob([JSON.stringify(tracking)], {
          type: "application/json",
        });
        navigator.sendBeacon(url, dataBlob);
      } else {
        await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(tracking),
          keepalive: true,
        });
      }
    };
    await sendAnalyticsData(trackingData);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      trackProgress();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedAnswers, zipCode]);
};

function Survey(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [miMultiselect, setMiMultiselect] = useState<string[]>([]);
  const [ttMultiselect, setTtMultiselect] = useState<string[]>([]);
  const [ageMultiselect, setAgeMultiselect] = useState<string[]>([]);
  const [stepperIndex, setStepperIndex] = useState<number>(0);
  const [stepperItems, setStepperItems] = useState<any>([]);
  const [showResults, setShowResults] = useState(false);
  const [feelMultiselect, setFeelMultiselect] = useState<string[]>([]);
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);
  const [updatedQuestions, setUpdatedQuestions] = useState<Question[]>([]);
  const params = useParams();

  const zip = params.zip ? params.zip : "";

  useSurveyTracker(selectedAnswers, zip);

  const createStepperItems = (arrlength: number) => {
    const tempStepper = [];

    for (let i = 1; i < arrlength; i += 1) {
      tempStepper.push({ label: i });
    }
    setStepperItems(tempStepper);
  };

  useEffect(() => {
    setIsLoading(true);

    QuestionService.getAllSurveyQuestions()
      .then((response) => {
        setAllQuestions(response);
        const filteredQuestions = response.filter(
          (question) => !question.isOptionalFilterQuestion,
        );
        setUpdatedQuestions(filteredQuestions);
        createStepperItems(filteredQuestions.length);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching questions:", e);
        setIsLoading(false);
        setError(e);
        return e;
      });
  }, []);

  const updateQuestionSequence = (selectedAnswer: Answer) => {
    console.log("Update Question Answer: ", selectedAnswer);
    setIsLoading(true);
    if (
      selectedAnswer.altQuestionSequence !== undefined &&
      selectedAnswer.altQuestionId !== undefined
    ) {
      const updatedQuestionsArray = [...updatedQuestions];
      const replacementQuestion = allQuestions.find(
        (question) => question.id === selectedAnswer.altQuestionId,
      );
      if (replacementQuestion) {
        const questionToReplace = updatedQuestionsArray.find(
          (question) =>
            question.sequence === selectedAnswer.altQuestionSequence,
        );
        if (questionToReplace) {
          const index = updatedQuestionsArray.indexOf(questionToReplace);
          updatedQuestionsArray[index] = replacementQuestion;
          setUpdatedQuestions(updatedQuestionsArray);
        }
      }
    } else {
      // Revert to the original sequence
      const filteredQuestions = allQuestions.filter(
        (question) => !question.isOptionalFilterQuestion,
      );
      setUpdatedQuestions(filteredQuestions);
    }
    setIsLoading(false);
  };

  const handleAnswerSelect = (questionId: number, answerId: number) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerId,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    QuestionService.getAllAnswers()
      .then((response) => {
        setAnswers(response);
        setIsLoading(false);
        return response;
      })
      .catch((e) => {
        console.error("Error fetching answers:", e);
        setIsLoading(false);
        return e;
      });
  }, [updatedQuestions]);

  // TODO: update design to be dragonfly loading bar
  const stepperContent = ({ current, disabled, focused, index }: StepProps) => {
    return (
      <Step
        current={current}
        disabled={disabled}
        focused={focused}
        index={index}
      >
        <span className="k-step-text">-</span>
      </Step>
    );
  };

  const handleStepperChange = (e: StepperChangeEvent) => {
    setStepperIndex(e.value);
  };

  const goToSurveyResultPage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Convert selectedAnswers object to array of values
    const allAnswerIds = Object.values(selectedAnswers);

    const combinedAnswers = [
      ...allAnswerIds,
      ...miMultiselect.map(Number),
      ...ageMultiselect.map(Number),
      ...ttMultiselect.map(Number),
      ...feelMultiselect.map(Number),
    ];

    console.log("Combined Answers:", combinedAnswers);
    setSelectedAnswers(combinedAnswers);
    setShowResults(true);
  };

  if (showResults) {
    return (
      <SurveyResults
        zipCode={zip}
        answers={selectedAnswers.map((item) => +item)}
      />
    );
  }

  const handleNext = () => {
    const currentQuestion = updatedQuestions[stepperIndex + 1];
    const selectedAnswerValue = selectedAnswers[currentQuestion.id];

    let selectedAnswer;
    if (Array.isArray(selectedAnswerValue)) {
      selectedAnswer = answers.find(
        (answer) =>
          selectedAnswerValue.includes(answer.id) &&
          answer.questionId === currentQuestion.id,
      );
    } else {
      selectedAnswer = answers.find(
        (answer) =>
          answer.id === selectedAnswerValue &&
          answer.questionId === currentQuestion.id,
      );
    }

    if (selectedAnswer && currentQuestion.hasAlternativePath) {
      updateQuestionSequence(selectedAnswer);
    }

    if (stepperIndex < updatedQuestions.length - 1) {
      setStepperIndex(stepperIndex + 1);
    }
  };

  const handleBack = () => {
    if (stepperIndex > 0) {
      setStepperIndex(stepperIndex - 1);
    }
  };

  const handleStartOver = () => {
    setSelectedAnswers([]);
    setMiMultiselect([]);
    setTtMultiselect([]);
    setAgeMultiselect([]);
    setFeelMultiselect([]);
    setStepperIndex(0);
    const filteredQuestions = allQuestions.filter(
      (question) => !question.isOptionalFilterQuestion,
    );
    setUpdatedQuestions(filteredQuestions);
  };

  const updateSelectedAnswer = (questionId: number, newAnswerId: number) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: newAnswerId,
    }));
  };

  return (
    <div
      className="surveyWrapperShell"
      style={{ backgroundImage: "url(/imgs/h4m_light_bg.png)" }}
    >
      <div
        className="surveyWrapper"
        style={{
          backgroundImage: "url(/imgs/h4m_path_survey_results_dark.svg)",
        }}
      >
        <div className="container centerflexbox flexdircol">
          <div className="surveyCard-header">
            {updatedQuestions
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((item, i) => {
                return (
                  stepperIndex === i - 1 && (
                    <div className="positive-messaging mb-5 pl-10 pr-10">
                      {item.additionalPageTopText}
                    </div>
                  )
                );
              })}
          </div>
          <Card className="surveyCard">
            <CardBody>
              {error && <div>{error}</div>}
              <div className="mt-2">
                {isLoading && (
                  <div className="loading-screen">
                    <div className="spinner" />
                    <p>Loading, please wait...</p>
                  </div>
                )}
                {!isLoading &&
                  updatedQuestions
                    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                    .map((item, i) => {
                      if (stepperIndex === i - 1) {
                        if (item.questionType !== "shortanswer") {
                          if (item.questionType === "singleselect") {
                            const singleSelectResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={singleSelectResults}
                                    chipListValue={
                                      selectedAnswers[item.id] || null
                                    }
                                    setChipListValue={(newAnswerId) =>
                                      updateSelectedAnswer(item.id, newAnswerId)
                                    }
                                    selection="single"
                                    selType="singleselect"
                                    onAnswerSelect={updateSelectedAnswer}
                                  />
                                </div>
                              )
                            );
                          }
                          if (item.questionType === "mimultiselect") {
                            const miResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={miResults}
                                    chipListValue={miMultiselect}
                                    setChipListValue={setMiMultiselect}
                                    selType="mi"
                                    selection="multiple"
                                    onAnswerSelect={(questionId, answerId) =>
                                      handleAnswerSelect(questionId, answerId)
                                    }
                                  />
                                </div>
                              )
                            );
                          }
                          if (item.questionType === "ttmultiselect") {
                            const ttResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={ttResults}
                                    chipListValue={ttMultiselect}
                                    setChipListValue={setTtMultiselect}
                                    selType="mi"
                                    selection="multiple"
                                    onAnswerSelect={(questionId, answerId) =>
                                      handleAnswerSelect(questionId, answerId)
                                    }
                                  />
                                </div>
                              )
                            );
                          }
                          if (item.questionType === "agemultiselect") {
                            const ageResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={ageResults}
                                    chipListValue={ageMultiselect}
                                    setChipListValue={setAgeMultiselect}
                                    selType="mi"
                                    selection="multiple"
                                    onAnswerSelect={(questionId, answerId) =>
                                      handleAnswerSelect(questionId, answerId)
                                    }
                                  />
                                </div>
                              )
                            );
                          }
                          if (item.questionType === "feelmultiselect") {
                            const feelResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={feelResults}
                                    chipListValue={feelMultiselect}
                                    setChipListValue={setFeelMultiselect}
                                    selType="mi"
                                    selection="multiple"
                                    onAnswerSelect={(questionId, answerId) =>
                                      handleAnswerSelect(questionId, answerId)
                                    }
                                  />
                                </div>
                              )
                            );
                          }
                          if (item.questionType === "boolean") {
                            const boolResults = answers
                              .filter((answer) => answer.questionId === item.id)
                              .sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1,
                              );
                            return (
                              stepperIndex === i - 1 && (
                                <div>
                                  <div className="question">
                                    {item.questionText}
                                    <div className="additional-text">
                                      {item.additionalInBoxText}
                                    </div>
                                  </div>
                                  <KendoChipList
                                    question={item}
                                    answers={boolResults}
                                    chipListValue={
                                      selectedAnswers[item.id] || null
                                    }
                                    setChipListValue={(newAnswerId) =>
                                      updateSelectedAnswer(item.id, newAnswerId)
                                    }
                                    selection="single"
                                    selType="boolean"
                                    onAnswerSelect={updateSelectedAnswer}
                                  />
                                </div>
                              )
                            );
                          }
                        }
                      }
                      return "";
                    })}
              </div>
            </CardBody>
          </Card>
          <div className="surveyButtonsWrapper">
            <div className="stepper-buttons">
              <BrandedPrimaryButton
                onClick={handleBack}
                text="Back"
                disabled={stepperIndex === 0}
                width="100px"
                className="primary stepper-back"
              />
              <div className="stepper-index-container">
                <Stepper
                  value={stepperIndex}
                  onChange={handleStepperChange}
                  items={stepperItems}
                  item={stepperContent}
                  className="stepper-index"
                />
              </div>
              {
                // Added stepperIndex +1 to skip over Enter Zip Question and - 2 on questions length for the correct count.
                // length is base 1 and stepper index is still base 0.
              }
              {stepperIndex + 1 <= updatedQuestions.length - 2 ? (
                <BrandedPrimaryButton
                  onClick={handleNext}
                  text="Next"
                  width="100px"
                  className="primary stepper-next"
                />
              ) : (
                <BrandedPrimaryButton
                  onClick={(next) => next.preventDefault()}
                  text="Next"
                  width="100px"
                  className="primary stepper-next-placeholder"
                />
              )}
            </div>
            <div className="text">
              If you do not wish to answer any more questions, you may show
              results at any time.
            </div>
            <div className="stepper-results-container">
              <BrandedPrimaryButton
                onClick={goToSurveyResultPage}
                width="200px"
                text="Show Results"
                className="mt-5 primary mb-5 stepper-results btnShowResults"
              />
              <Button
                title="Start Over"
                onClick={handleStartOver}
                className="mt-5 mb-5 ml-3 tertiary"
              >
                Start Over
              </Button>
            </div>
          </div>
        </div>

        <div className="surveyBottomWrap">
          <div className="surveyBottom">
            {stepperIndex === 0 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-0-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 1 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-1-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 2 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-2-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 3 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-4-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 4 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-5-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 5 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-6-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 6 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-7-v2.svg)",
                }}
              />
            )}
            {stepperIndex === 7 && (
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(/imgs/step-7-v2.svg)",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Survey;
